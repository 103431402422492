.support-button {
  position: relative;

  &:hover {
    .support-button__menu {
      height: 222px;
      visibility: visible;
    }
  }

  &__btn {
    width: 148px;
    height: 40px;
    border-radius: 12px;
    background: rgba(7, 6, 0, 0.06);
    font-size: 14px;
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background: rgba(7, 6, 0, 0.1);
    }

    @media (max-width: 560px) {
      width: 40px;

      span {
        display: none;
      }
    }
  }

  &__menu {
    border: 1px solid transparent;
    visibility: hidden;
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 282px;
    z-index: 2;
    top: 100%;
    transition: .2s;
    height: 0;
    padding: 8px 0 0;
  }

  &__menu-content {
    border: 1px solid #D0D5DD;
    background: #fff;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    padding: 14px 0 24px;
  }

  &__menu-item {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    color: #000;
    padding: 6px 20px;
    cursor: pointer;
    text-decoration: none;
    transition: .3s;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  &__social-btn {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: rgba(18, 18, 18, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__divider {
    width: calc(100% - 40px);
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 14px auto 20px;
  }

  &__label {
    color: #7A7A7A;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: 'eUkraine', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-width: 320px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto;
}

@keyframes rotate {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%   {
    box-shadow: 0 0 0 0 rgba(210, 168, 203, 0.5);
  }
  50% {
    box-shadow: 0 0 0 7px rgba(210, 168, 203, 0.5);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(210, 168, 203, 0);
  }
}

.button-main {
  font-family: 'eUkraine-head', sans-serif;
  background: #8F257D;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 16px;
  max-width: 140px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 48px;

  &:hover {
    background: #7e216d;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    background: #651b58;
  }

  &--success {
    background: #000;
    cursor: default;
    pointer-events: none;
  }

  &__loader {
    width: 23px;
    height: 23px;
    animation: rotate 1s linear infinite;
  }
}

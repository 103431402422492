.faq {
  padding: 70px 0 140px;

  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }

  &__title {
    color: #030102;
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    gap: 40px;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 50px;
    }
  }

  &__left-icon {
    width: 120px;
    height: 120px;
    background: #F5F6F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 56px;
    margin-bottom: 16px;

    @media (max-width: 560px) {
      width: 64px;
      height: 64px;
      font-size: 29px;
    }
  }

  &__left {
    max-width: 400px;

    h3 {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 8px;

      @media (max-width: 560px) {
        font-size: 18px;
      }
    }

    p {
      font-size: 14px;
      color: rgba(3, 1, 2, 0.64);
      font-weight: 300;
      line-height: 22px;
      max-width: 330px;
    }
  }

  .support-button {
    svg {
      path {
        stroke: #fff;
      }
    }

    &__btn {
      height: 48px;
      background: #8F257D;
      margin-top: 32px;
      color: #fff;

      @media (max-width: 560px) {
        width: 142px;

        span {
          display: inline;
        }
      }
    }

    &__menu {
      right: auto;
      left: 0;
    }
  }

  &__right {
    width: 100%;
    max-width: 800px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  &__item {
    background: rgba(3, 1, 2, 0.04);
    border-radius: 16px;
    width: 100%;

    &.active {
      .faq {
        &__item-content {
          display: block;
        }

        &__icon-plus {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__icon-plus {
    //transition: .4s;
  }

  &__item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    padding: 16px;
    gap: 24px;

    span {
      color: #8F257D;
    }

    @media (max-width: 560px) {
      font-size: 16px;
    }

    @media (max-width: 375px) {
      font-size: 15px;
    }
  }

  &__item-content {
    font-size: 14px;
    line-height: 24px;
    color: rgba(3, 1, 2, 0.64);
    font-weight: 300;
    overflow: hidden;
    padding: 0 16px 16px;
    display: none;

    p {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: #F2994A;
    }

    ol,
    ul {
      padding-left: 20px;
    }

    @media (max-width: 560px) {
      font-size: 12px;
    }
  }
}
.footer {
  padding: 0 0 32px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    background: rgba(3, 1, 2, 0.04);
    padding: 32px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 16px;
      gap: 40px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 140px;
  }

  &__right {
    display: flex;
    gap: 40px;

    @media (max-width: 830px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  a,
  p {
    margin: 0;
    font-size: 14px;
    color: #5E5D5D;
    font-weight: 300;
    line-height: 24px;

    @media (max-width: 350px) {
      font-size: 13px;
    }
  }
}
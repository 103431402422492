.mobile {
  padding: 70px 0 140px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 50px 0 100px;
  }

  &__title {
    color: #030102;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'eUkraine-head', sans-serif;
    font-weight: 500;

    @media (max-width: 560px) {
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      gap: 50px;
    }
  }

  &__left {
    width: 100%;
    max-width: 560px;

    @media (max-width: 960px) {
      max-width: none;
    }
  }

  &__right {
    position: relative;
    z-index: 1;
    width: 100%;
    min-width: 600px;
    max-width: 600px;

    div {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      position: relative;

      @media (max-width: 480px) {
        max-width: 150px;
      }
    }

    @media (max-width: 1100px) and (min-width: 960px) {
      min-width: 450px;
      max-width: 450px;
    }

    @media (max-width: 960px) {
      min-width: auto;
    }
  }

  &__screen {
    width: 100%;
    max-width: 219px;
    margin: 0 auto;
    display: block;
    z-index: 2;
  }

  &__msg-1 {
    width: 100%;
    max-width: 180px;
    position: absolute;
    z-index: 3;
    left: -75px;
    bottom: 90px;

    @media (max-width: 480px) {
      max-width: 130px;
      bottom: 75px;
      left: -85px;
    }
  }

  &__msg-2 {
    width: 100%;
    max-width: 170px;
    position: absolute;
    z-index: -1;
    right: -65px;
    top: 40px;

    @media (max-width: 480px) {
      max-width: 110px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: 560px) {
      font-size: 14px;
    }
  }

  &__apps {
    display: flex;
    gap: 16px;
    margin-top: 48px;

    @media (max-width: 560px) {
      margin-top: 32px;
    }
  }
}